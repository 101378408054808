exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-500-tsx": () => import("./../../../src/pages/500.tsx" /* webpackChunkName: "component---src-pages-500-tsx" */),
  "component---src-pages-about-tsx": () => import("./../../../src/pages/about.tsx" /* webpackChunkName: "component---src-pages-about-tsx" */),
  "component---src-pages-account-tsx": () => import("./../../../src/pages/account.tsx" /* webpackChunkName: "component---src-pages-account-tsx" */),
  "component---src-pages-add-student-tsx": () => import("./../../../src/pages/add-student.tsx" /* webpackChunkName: "component---src-pages-add-student-tsx" */),
  "component---src-pages-admin-tsx": () => import("./../../../src/pages/admin.tsx" /* webpackChunkName: "component---src-pages-admin-tsx" */),
  "component---src-pages-assigned-students-tsx": () => import("./../../../src/pages/assigned-students.tsx" /* webpackChunkName: "component---src-pages-assigned-students-tsx" */),
  "component---src-pages-assigned-tsx": () => import("./../../../src/pages/assigned.tsx" /* webpackChunkName: "component---src-pages-assigned-tsx" */),
  "component---src-pages-business-tsx": () => import("./../../../src/pages/business.tsx" /* webpackChunkName: "component---src-pages-business-tsx" */),
  "component---src-pages-catalog-tsx": () => import("./../../../src/pages/catalog.tsx" /* webpackChunkName: "component---src-pages-catalog-tsx" */),
  "component---src-pages-checkout-tsx": () => import("./../../../src/pages/checkout.tsx" /* webpackChunkName: "component---src-pages-checkout-tsx" */),
  "component---src-pages-confirmation-tsx": () => import("./../../../src/pages/confirmation.tsx" /* webpackChunkName: "component---src-pages-confirmation-tsx" */),
  "component---src-pages-contact-tsx": () => import("./../../../src/pages/contact.tsx" /* webpackChunkName: "component---src-pages-contact-tsx" */),
  "component---src-pages-course-[id]-tsx": () => import("./../../../src/pages/course/[id].tsx" /* webpackChunkName: "component---src-pages-course-[id]-tsx" */),
  "component---src-pages-course-success-tsx": () => import("./../../../src/pages/course/success.tsx" /* webpackChunkName: "component---src-pages-course-success-tsx" */),
  "component---src-pages-course-tsx": () => import("./../../../src/pages/course.tsx" /* webpackChunkName: "component---src-pages-course-tsx" */),
  "component---src-pages-courses-tsx": () => import("./../../../src/pages/courses.tsx" /* webpackChunkName: "component---src-pages-courses-tsx" */),
  "component---src-pages-dashboard-tsx": () => import("./../../../src/pages/dashboard.tsx" /* webpackChunkName: "component---src-pages-dashboard-tsx" */),
  "component---src-pages-edit-student-tsx": () => import("./../../../src/pages/edit-student.tsx" /* webpackChunkName: "component---src-pages-edit-student-tsx" */),
  "component---src-pages-faq-tsx": () => import("./../../../src/pages/faq.tsx" /* webpackChunkName: "component---src-pages-faq-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-learning-tsx": () => import("./../../../src/pages/learning.tsx" /* webpackChunkName: "component---src-pages-learning-tsx" */),
  "component---src-pages-org-admin-tsx": () => import("./../../../src/pages/org-admin.tsx" /* webpackChunkName: "component---src-pages-org-admin-tsx" */),
  "component---src-pages-path-tsx": () => import("./../../../src/pages/path.tsx" /* webpackChunkName: "component---src-pages-path-tsx" */),
  "component---src-pages-payment-tsx": () => import("./../../../src/pages/payment.tsx" /* webpackChunkName: "component---src-pages-payment-tsx" */),
  "component---src-pages-post-tsx": () => import("./../../../src/pages/post.tsx" /* webpackChunkName: "component---src-pages-post-tsx" */),
  "component---src-pages-profile-tsx": () => import("./../../../src/pages/profile.tsx" /* webpackChunkName: "component---src-pages-profile-tsx" */),
  "component---src-pages-review-tsx": () => import("./../../../src/pages/review.tsx" /* webpackChunkName: "component---src-pages-review-tsx" */),
  "component---src-pages-search-tsx": () => import("./../../../src/pages/search.tsx" /* webpackChunkName: "component---src-pages-search-tsx" */),
  "component---src-pages-students-tsx": () => import("./../../../src/pages/students.tsx" /* webpackChunkName: "component---src-pages-students-tsx" */),
  "component---src-pages-success-tsx": () => import("./../../../src/pages/success.tsx" /* webpackChunkName: "component---src-pages-success-tsx" */),
  "component---src-pages-support-tsx": () => import("./../../../src/pages/support.tsx" /* webpackChunkName: "component---src-pages-support-tsx" */)
}

