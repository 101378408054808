// src/features/auth/authSlice.ts
import { createSlice, Dispatch, PayloadAction } from '@reduxjs/toolkit';
import axios from "axios";

type initialState = {
    loading: boolean,
    error: boolean,
    items: [],
};

export const initialState = {
    loading: false,
    error: false,
    items: [],
};

const userSlice = createSlice({
    name: "user",
    initialState,
    reducers: {
        setLoading: (state) => {
            state.loading = true;
        },
        setItems: (state, {payload}) => {
            state.loading = false;
            state.error = false;
            state.items = payload;
        },
        setError: (state) => {
            state.error = true;
        }
    }
});

export const { setLoading, setItems, setError } = userSlice.actions;

export const userSelector = (state: { items: any; }) => state.items;

export default userSlice.reducer;

const token = typeof window !== 'undefined' && localStorage.getItem('access_token');
// console.log('this is the token: ***** ',token);

const api = axios.create({
    baseURL: process.env.API_URL,
    withCredentials: false,
    headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
    }
});

export function fetchUser(userId: string) {
    return async (dispatch: Dispatch) => {
        await api
        .get('/api/users/' + userId )
        .then( (response) => {
            dispatch(setItems(response.data));
        })
        .catch( (error) => {
            dispatch(setError());
        })
    }
}

export function clearUser() {
    return async (dispatch: Dispatch) => {
        dispatch(setItems([]));
    }
}