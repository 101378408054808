// src/features/auth/authSlice.ts
import { createSlice, Dispatch, PayloadAction } from '@reduxjs/toolkit';
import axios from "axios";
import jwt_decode from "jwt-decode";

type initialState = {
    loading: boolean,
    error: boolean,
    message: string,
    items: [],
};

export const initialState = {
    loading: false,
    error: false,
    message: '',
    items: [],
};

const loginSlice = createSlice({
    name: "login",
    initialState,
    reducers: {
        setLoading: (state) => {
            state.loading = true;
            state.message = '';
        },
        setItems: (state, {payload}) => {
            state.loading = false;
            state.error = false;
            state.items = payload;
            state.message = '';
        },
        setError: (state, {payload}) => {
            state.error = true;
            state.message = payload;
        },
        negateError: (state) => {
            state.error = false;
            state.message = '';
        }
    }
});

export const { setLoading, setItems, setError, negateError } = loginSlice.actions;

export const loginSelector = (state: { items: any; }) => state.items;

export default loginSlice.reducer;

const token = typeof window !== 'undefined' && localStorage.getItem('access_token');

const api = axios.create({
    baseURL: process.env.API_URL,
    withCredentials: false,
    headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
    }
});

export function fetchLogin(user: string, password: string) {
    return async (dispatch: Dispatch) => {
        dispatch(negateError())
        await api
        .post('/api/auth', { username: user, password: password })
        .then( (response) => {
            // console.log('the response: ***** ',response.data.access_token);
            dispatch(setItems(response.data));
            typeof window !== 'undefined' && localStorage.setItem('access_token', response.data.access_token);
        })
        .catch( (error) => {
            dispatch(setError(error.response.data.message));
        })
    }
}

export function fetchLoginCart(user: string, password: string) {
    return async (dispatch: Dispatch) => {
        console.log('using this login');
        let element: any = [];
        let firstElement: any;
        dispatch(negateError())

        
            await api.post('/api/auth', { username: user, password: password })
            .then( async (response) => {
                // console.log('the response',response)
                firstElement = response.data;

                // console.log(firstElement)

                const apiToken = axios.create({
                    baseURL: process.env.API_URL,
                    withCredentials: false,
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer ' + firstElement.access_token
                    }
                });

                let currentCart = await apiToken.get('/api/shopping-car/current');

                // console.log('the status: **** ',currentCart);

                if(!currentCart.data.status) {
                    let element: boolean = false;

                    let data = JSON.stringify({
                        "courses": []
                    });

                    var config = {
                        method: 'post',
                        url: process.env.API_URL + '/api/shopping-car',
                        headers: {
                            'Accept': 'application/json',
                            'Content-Type': 'application/json',
                            'Authorization': 'Bearer ' + firstElement.access_token
                        },
                        data: data
                    };

                    const newCart = await axios(config);
        
                    
                    // console.log('the new cart: **** ',newCart);

                    currentCart = await apiToken.get('/api/shopping-car/current');
                    const newCart2 = await axios(config);
                }
        
                element = [firstElement,currentCart.data];
            })
            .catch( error => {
        
                console.log(error.response);
                firstElement = error.response.data.message;
                dispatch(setError(error.response.data.message));
            } )

            // // dispatch(setItems(userInfo.data));
    

            // if(!currentCart.data.status) {
            //     let element: boolean = false;

            //     let data = JSON.stringify({
            //         "courses": []
            //     });

            //     var config = {
            //         method: 'post',
            //         url: process.env.API_URL + '/api/shopping-car',
            //         headers: {
            //             'Accept': 'application/json',
            //             'Content-Type': 'application/json',
            //             'Authorization': 'Bearer ' + userInfo.data.access_token
            //         },
            //         data: data
            //     };

            //     const newCart = await axios(config);
                
            //     console.log('the new cart: **** ',newCart);

            //     currentCart = await apiToken.get('/api/shopping-car/current');
                
                // return element;
            // }
    
            
        

        return element;
    }
}

export function fillLogin(loginInfo: any) {
    return async (dispatch: Dispatch) => {
        dispatch(setItems(loginInfo));
    }
}

export function logOut() {
    return async (dispatch: Dispatch) => {
        dispatch(setItems([]));
    }
}