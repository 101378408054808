// src/features/auth/authSlice.ts
import { createSlice, Dispatch, PayloadAction } from '@reduxjs/toolkit';
import axios from "axios";

type initialState = {
    loading: boolean,
    error: boolean,
    items: any[],
};

export const initialState = {
    loading: false,
    error: false,
    items: [],
};

const enrollSlice = createSlice({
    name: "enroll",
    initialState,
    reducers: {
        setLoading: (state) => {
            state.loading = true;
        },
        setItems: (state, { payload }) => {
            state.loading = false;
            state.error = false;
            state.items = payload;
        },
        setError: (state) => {
            state.error = true;
        }
    }
});

export const { setLoading, setItems, setError } = enrollSlice.actions;

export const enrollSelector = (state: { items: any; }) => state.items;

export default enrollSlice.reducer;

export function fetchEnrolls(token: string) {
    return async (dispatch: Dispatch) => {

        const api = axios.create({
            baseURL: process.env.API_URL,
            withCredentials: false,
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token
            }
        });

        await api
            .get('/api/enrollment')
            .then((response) => {
                console.log('obtaining the information for enrollments: **** ',response.data);
                    dispatch(setItems(response.data));
            })
            .catch((error) => {
                dispatch(setError());
            })
    }
}


export function cleanEnroll() {
    return async (dispatch: Dispatch) => {
        dispatch(setItems([]));
    }
}

