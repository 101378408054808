// src/features/auth/authSlice.ts
import { createSlice, Dispatch, PayloadAction } from '@reduxjs/toolkit';
import axios from "axios";

type initialState = {
    loading: boolean,
    error: boolean,
    items: any[],
};

export const initialState = {
    loading: false,
    error: false,
    items: [],
};

const indexItemsSlice = createSlice({
    name: "indexItems",
    initialState,
    reducers: {
        setLoading: (state) => {
            state.loading = true;
        },
        setItems: (state, { payload }) => {
            state.loading = false;
            state.error = false;
            state.items = payload;
        },
        setError: (state) => {
            state.error = true;
        }
    }
});

export const { setLoading, setItems, setError } = indexItemsSlice.actions;

export const indexItemSelector = (state: { items: any; }) => state.items;

export default indexItemsSlice.reducer;

const loopItems = async (uuids: any) => {

    let toItems: any = [];

    const api = axios.create({
        baseURL: process.env.API_URL,
        withCredentials: false,
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        }
    });

    await uuids.map( async (item: any) => {
        await api
        .get(`/api/info/algolia/search?query=${item}&limit=12&page=0`)
        .then((response) => {
            toItems.push(response.data.data.hits[0]);
            // dispatch(setItems(response.data));
        })
    })

    return toItems;
}

export function fetchItems(uuids: any) {
    return async (dispatch: Dispatch) => {
        const itemsToGet = await loopItems(uuids)
        dispatch(setItems(itemsToGet));
    }
}

export function cleanItems() {
    return async (dispatch: Dispatch) => {
        dispatch(setItems([]));
    }
}

