// src/features/auth/authSlice.ts
import { createSlice, Dispatch, PayloadAction } from '@reduxjs/toolkit';
import axios from "axios";

type initialState = {
    loading: boolean,
    error: boolean,
    items: any[],
};

export const initialState = {
    loading: false,
    error: false,
    items: [],
};

const quantitySlice = createSlice({
    name: "quantity",
    initialState,
    reducers: {
        setLoading: (state) => {
            state.loading = true;
        },
        setItems: (state, { payload }) => {
            state.loading = false;
            state.error = false;
            state.items = payload;
        },
        setError: (state) => {
            state.error = true;
        }
    }
});

export const { setLoading, setItems, setError } = quantitySlice.actions;

export const quantitySelector = (state: { items: any; }) => state.items;

export default quantitySlice.reducer;

export function getQuantity(token: string, quantity: number, uuid: string) {

    return async (dispatch: Dispatch) => {

        const data = JSON.stringify({
            "courseUuid": uuid,
            "quantity": quantity
        });

        var config = {
            method: 'patch',
            url: process.env.API_URL + '/api/shopping-car/item',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token
            },
            data: data
        };

        await axios(config)
            .then(function (response) {
                dispatch(setItems(response.data));
            })
            .catch(function (error) {
                dispatch(setError());
            });
    }
}

export function cleanQuantity() {
    return async (dispatch: Dispatch) => {
        dispatch(setItems([]));
    }
}

