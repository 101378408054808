import { combineReducers } from '@reduxjs/toolkit'
import cartSlice from './cartSlice';
import enrollSlice from './enrollSlice';
import indexItemsSlice from './indexItemsSlice';
import loginSlice from './loginSlice';
import quantitySlice from './quantitySlice';
import userSlice from './userSlice';


const rootReducer = combineReducers({
    loginSlice,
    cartSlice,
    quantitySlice,
    indexItemsSlice,
    userSlice,
    enrollSlice
})

export type RootState = ReturnType<typeof rootReducer>;

export default rootReducer