import React from "react"
import { Provider } from "react-redux"
import store, { persistor } from "./src/store/store"
import { PersistGate } from 'redux-persist/integration/react'

// import store from "./src/store/store"

// eslint-disable-next-line react/display-name,react/prop-types
const wrapWithProvider = ({ element }) => {
  // Instantiating store in `wrapRootElement` handler ensures:
  //  - there is fresh store for each SSR page
  //  - it will be called only once in browser, when React mounts
  return <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        {element}
      </PersistGate>
    </Provider>
}

export default wrapWithProvider;